import type { Action } from '../actions';
import * as actions from '../actions';

export type Prediction = {
  completion: string;
  id: number;
};

export type CodeExplanationInput = {
  courseTitle: string;
  instructions: string;
  language: string;
  pec: string;
  solution: string;
};

export type CodeExplanation =
  | {
      prediction?: Prediction;
      type: 'streaming' | 'success';
    }
  | {
      type: 'error' | 'initial' | 'loading';
    };

export type CodeExplanationState = {
  codeExplanation: CodeExplanation;
};

const initialState: CodeExplanationState = {
  codeExplanation: { type: 'initial' },
};

export type GptStreamType =
  | { choices: Array<{ text: string }>; type: 'gpt-partial-answer' }
  | { id: number; type: 'gpt-id' }
  | { reason: string; type: 'gpt-error' };

export default (state = initialState, action: Action): CodeExplanationState => {
  switch (action.type) {
    case actions.EPIC_START_SESSION:
      return {
        ...state,
        codeExplanation: { type: 'initial' },
      };
    case actions.SET_CODE_EXPLANATION:
      return {
        ...state,
        codeExplanation: action.data,
      };
    case actions.SET_CODE_EXPLANATION_TYPE:
      return {
        ...state,
        codeExplanation: {
          ...state.codeExplanation,
          type: action.codeExplanationType,
        },
      };
    case actions.STREAM_CODE_EXPLANATION:
      switch (state.codeExplanation.type) {
        case 'streaming':
          if (state.codeExplanation.prediction) {
            return {
              ...state,
              codeExplanation: {
                ...state.codeExplanation,
                prediction: {
                  ...state.codeExplanation.prediction,
                  completion:
                    state.codeExplanation.prediction.completion +
                    action.partialCompletion,
                },
              },
            };
          }
          return state;
        case 'initial':
        case 'success':
        case 'loading':
        case 'error':
        default:
          return state;
      }
    default:
      return state;
  }
};
