import Cookies from 'js-cookie';

import config from '../../config';
import { MODAL_TYPE, selectCourse, shouldShowSurveyModal } from '../selectors';

import * as actions from '.';

export const showDashboardSurvey = ({ redirectUrl }: any) => (
  dispatch: any,
) => {
  const today = Date.now();
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  Cookies.set('lx-dashboard-survey', today);

  dispatch(
    actions.showModal({
      modal: { ...MODAL_TYPE.DASHBOARD_SURVEY, redirectUrl },
    }),
  );
};

export const goBackToDashboard = ({
  redirectUrl,
  shouldShowSurveyModalSelector = shouldShowSurveyModal,
  showDashboardSurveyActionCreator = showDashboardSurvey,
}: any) => (dispatch: any, getState: any) => {
  const state = getState();

  if (shouldShowSurveyModalSelector(state)) {
    dispatch(showDashboardSurveyActionCreator({ redirectUrl }));
    return;
  }
  window.location.href = redirectUrl;
};

export const saveBackToDashboardSurvey = ({
  checks,
  redirectUrl,
  text,
}: // @ts-expect-error ts-migrate(6133) FIXME: 'dispatch' is declared but its value is never read... Remove this comment to see the full error message
any) => async (dispatch: any, getState: any) => {
  const state = getState();
  const courseId = selectCourse(state).get('id');

  const campusApiSurveyEndpoint = `${config.serviceUrls.campusApi}/api/back-to-dashboard-survey/answer`;
  const body = JSON.stringify({
    answers: checks,
    comment: text,
    course_id: courseId,
  });
  try {
    await fetch(campusApiSurveyEndpoint, {
      body,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    // eslint-disable-next-line no-empty
  } catch (ex) {
  } finally {
    window.location.href = redirectUrl;
  }
};
