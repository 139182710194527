import identity from 'lodash/identity';
import upperFirst from 'lodash/upperFirst';
import { createSelector } from 'reselect';
import striptags from 'striptags';

import config from '../../config';
import { DEFAULT_LANGUAGE } from '../../i18n';
import type { LanguageMap } from '../middlewares/boot/getTranslatedCoursesAsPromise';

import * as chapterSelectors from './chapter';
import * as courseSelectors from './course';
import * as exercisesSelectors from './exercises';
import { selectLearningMode } from './learningMode';
import { selectCanonical, selectPathLanguage } from './location';
import { selectTranslatedCourses } from './translatedCourses';

export const selectPageTitle = createSelector(
  exercisesSelectors.selectExercise,
  chapterSelectors.selectChapter,
  courseSelectors.selectCourse,
  courseSelectors.selectTechnology,
  (exercise, chapter, course, technology) =>
    [
      exercise.get('title') || chapter.get('title') || course.get('title'),
      technology || upperFirst(course.get('programming_language')),
    ]
      .filter(identity)
      .join(' | '),
);

export const selectPageDescription = createSelector(
  exercisesSelectors.selectExercise,
  chapterSelectors.selectChapter,
  courseSelectors.selectCourse,
  (exercise, chapter, course) => {
    const getInfo = (info: any) =>
      exercise.get(info) || chapter.get(info) || course.get(info);
    return `Here is an example of ${striptags(getInfo('title'))}: ${
      striptags(getInfo('assignment')).split('.')[0]
    }.`;
  },
);

export const selectEditorTabs = createSelector(
  exercisesSelectors.selectExercise,
  exercisesSelectors.selectOriginalEditorTabs,
  exercisesSelectors.selectExerciseProgress,
  exercisesSelectors.selectCurrentSubExercise,
  (exercise, originalEditorTabs, progress, subExercise) => {
    return originalEditorTabs
      .map((originalEditorTab: any) => {
        if (
          originalEditorTab.has('props') &&
          !originalEditorTab.get('props').has('resetCode')
        ) {
          return originalEditorTab.setIn(
            ['props', 'resetCode'],
            exercise.get('sample_code'),
          );
        }

        return originalEditorTab;
      })
      .map((originalEditorTab: any) => {
        if (!originalEditorTab.has('props')) {
          return originalEditorTab;
        }

        const lastAttempt = subExercise.isEmpty()
          ? progress.get('last_attempt')
          : subExercise.get('last_attempt');

        const defaultCode =
          progress.get('used_solution') === true &&
          lastAttempt === exercise.get('solution')
            ? exercise.get('sample_code')
            : lastAttempt || exercise.get('sample_code');

        return originalEditorTab.updateIn(['props', 'code'], (code: any) =>
          code !== null ? code : defaultCode,
        );
      });
  },
);

export const shouldCourseBeIndexed = (
  course: any,
  translatedCourses: LanguageMap,
  language = DEFAULT_LANGUAGE,
): boolean => {
  // TODO remove this when fr translation roll up is done
  if (language === 'fr') {
    return false;
  }

  const courseId = course.get('id');
  const isLtiOnly = course.get('lti_only');
  const shouldCache = course.get('should_cache', true);
  const isArchived = course.get('archived_at') != null;
  const isCapstoneCourse = courseId === 752;
  const isProductionDomain = config.isProductionDomain();

  const isSupportedCourse =
    translatedCourses[course.get('id')]?.includes(language) ?? false;

  const isDefaultLanguage = (language ?? DEFAULT_LANGUAGE) === DEFAULT_LANGUAGE;

  return (
    !isLtiOnly &&
    shouldCache &&
    !isArchived &&
    !isCapstoneCourse &&
    isProductionDomain &&
    (isDefaultLanguage || isSupportedCourse)
  );
};

export const selectHeadAttributes = createSelector(
  selectPageTitle,
  selectPageDescription,
  selectCanonical,
  courseSelectors.selectCourse,
  selectLearningMode,
  selectPathLanguage,
  selectTranslatedCourses,
  (
    title,
    description,
    canonical,
    course,
    learningMode,
    language,
    translatedCourses,
  ) => {
    return {
      title,
      description,
      canonical,
      shouldBeIndexed: shouldCourseBeIndexed(
        course,
        translatedCourses,
        language,
      ),
      isSingleExerciseMode: learningMode === 'singleExercise',
    };
  },
);
