import type { MultiplexerJsonRpcSessionManager } from '../../helpers/jsonRpc/multiplexerJsonRpcSessionManager';
import type { Action } from '../actions';
import * as actions from '../actions';

type InitialBackendSessionJsonRpcState = {
  status: 'initial';
};

type LoadedBackendSessionJsonRpcState = {
  muxSessionManager: MultiplexerJsonRpcSessionManager;
  status: 'loaded';
};

export type BackendSessionJsonRpcState =
  | InitialBackendSessionJsonRpcState
  | LoadedBackendSessionJsonRpcState;

const initialState: BackendSessionJsonRpcState = { status: 'initial' };

export default function backendSessionJsonRpcReducer(
  state: BackendSessionJsonRpcState = initialState,
  action: Action,
): BackendSessionJsonRpcState {
  switch (action.type) {
    case actions.SET_MUX_SESSION_MANAGER:
      return {
        status: 'loaded',
        muxSessionManager: action.muxSessionManager,
      };
    default:
      return state;
  }
}
