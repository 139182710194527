import type { ContentAuthorizationDenyReason } from '../../helpers/ContentAuthorizationClient/ContentAuthorizationClient';
import type { Action } from '../actions';
import * as actions from '../actions';
import { ContentAuthorizationStatus } from '../actions';

const initialState: ContentAuthorizationState = {};

type ChapterContentAuthorizationLoading = {
  status: ContentAuthorizationStatus.loading;
};
type ChapterContentAuthorizationGranted = {
  status: ContentAuthorizationStatus.granted;
};
type ChapterContentAuthorizationDenied = {
  denyReason: ContentAuthorizationDenyReason;
  status: ContentAuthorizationStatus.denied;
};

export type ChapterContentAuthorization =
  | ChapterContentAuthorizationDenied
  | ChapterContentAuthorizationGranted
  | ChapterContentAuthorizationLoading;

export type ContentAuthorizationState = {
  [key: number]: ChapterContentAuthorization;
};

export default (
  state = initialState,
  action: Action,
): ContentAuthorizationState => {
  switch (action.type) {
    case actions.CONTENT_AUTHORIZATION_STATUS_UPDATED:
      if (action.status === ContentAuthorizationStatus.denied) {
        return {
          ...state,
          [action.chapterId]: {
            status: action.status,
            denyReason: action.reason,
          },
        };
      }
      return {
        ...state,
        [action.chapterId]: {
          status: action.status,
        },
      };
    default:
      return state;
  }
};
