import type { SessionOutput } from '@datacamp/multiplexer-client';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import { outputsForConsole } from '../../helpers/outputs';
import { getMux } from '../../helpers/pluginManager';
import stripAnsiColors from '../../helpers/stripAnsiColors';
import type { RegisterConsoleOutputCallback } from '../actions';
import * as actions from '../actions';

const isConsoleOutput = (outputs: SessionOutput | SessionOutput[]): boolean => {
  if (Array.isArray(outputs)) {
    return outputs.some((output) => outputsForConsole.includes(output.type));
  }
  return outputsForConsole.includes(outputs.type);
};

const stripAnsiFromConsoleOutput = (output: SessionOutput): SessionOutput => ({
  ...output,
  payload:
    typeof output.payload === 'string'
      ? stripAnsiColors(output.payload)
      : output.payload,
});

const sanitizeAndPassOutputsToConsole = (
  callbackAction: RegisterConsoleOutputCallback,
) => (outputs: SessionOutput[]) => {
  callbackAction.callback(outputs.map(stripAnsiFromConsoleOutput));
};

export const epicRegisterOutputMuxCallbacks = (action$: any) => {
  let subscription: any;
  return action$
    .ofType(actions.REGISTER_CONSOLE_OUTPUT_CALLBACK)
    .combineLatest(
      action$.ofType(actions.EPIC_MUX_REGISTERED),
      action$.ofType(actions.EPIC_START_SESSION),
      (callbackAction: RegisterConsoleOutputCallback) => callbackAction,
    )
    .do((callbackAction: RegisterConsoleOutputCallback) => {
      // Ensure only one subscription is active
      if (subscription) {
        // Unsubscribe once all the outputs are consumed.
        // This will prevent bugs where outputs are not printed in tab exercises
        // because a new session is started directly once the sub exercise has been completed.
        const oldSubscription = subscription;
        requestAnimationFrame(() => oldSubscription.unsubscribe());
      }
      const mux = getMux();
      if (mux) {
        subscription = mux.subscribeToOutputs(
          isConsoleOutput,
          sanitizeAndPassOutputsToConsole(callbackAction),
        );
      }
    })
    .concatMapTo(Rx.Observable.empty());
};
