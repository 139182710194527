import { mobileApi } from '../../helpers/api/clients';
import * as selectors from '../selectors';

export const DAILY_STREAK_MOBILE_LINK_SENT =
  'dailyStreak/DAILY_STREAK_MOBILE_LINK_SENT';
export const dailyStreakLinkSent = () => ({
  type: DAILY_STREAK_MOBILE_LINK_SENT,
});

export const DAILY_STREAK_MOBILE_LINK_FAILED =
  'dailyStreak/DAILY_STREAK_MOBILE_LINK_FAILED';
export const dailyStreakLinkFailed = () => ({
  type: DAILY_STREAK_MOBILE_LINK_FAILED,
});

export const DAILY_STREAK_MOBILE_LINK_SENDING =
  'dailyStreak/DAILY_STREAK_MOBILE_LINK_SENDING';
export const dailyStreakLinkSending = () => ({
  type: DAILY_STREAK_MOBILE_LINK_SENDING,
});

export const sendDailyStreakMobileLink = () => (
  dispatch: any,
  getState: any,
) => {
  dispatch(dailyStreakLinkSending());

  const state = getState();
  const courseId = selectors.selectCourse(state).get('id');
  const chapterId = selectors.selectChapterId(state);
  const exerciseId = selectors.selectExercise(state).get('id');

  return mobileApi
    .sendDailyStreakMobileLink({ courseId, chapterId, exerciseId })
    .then(() => dispatch(dailyStreakLinkSent()))
    .catch(() => dispatch(dailyStreakLinkFailed()));
};
