import type { Reducer } from '../../../../interfaces/State';
import type {
  ExerciseCompletedAction,
  StreakInfoResponse,
} from '../../../actions';
import {
  BOOT_SUCCEEDED,
  EXERCISE_COMPLETED,
  HIDE_DAILY_STREAK_SCREEN,
  SHOW_DAILY_STREAK_SCREEN,
  STREAK_INFO_UPDATED,
} from '../../../actions';
import type { StreakInfoKnownState, StreakInfoState } from '../types';
import { StreakInfoType } from '../types';

const initialState: StreakInfoState = { type: StreakInfoType.Unknown };

const storeStreakInfo = (
  state: StreakInfoState,
  streakInfo?: StreakInfoResponse | null,
): StreakInfoState => {
  if (!streakInfo) {
    return state;
  }

  return {
    version: 1,
    streakGoalMetInSession: false,
    screenVisible: false,
    type: StreakInfoType.Known,
    dailyXp: streakInfo.daily_xp,
    lengthInDays: streakInfo.streak.days,
    createdAt: streakInfo.streak.created_at,
    incrementedAt: streakInfo.streak.last_incremented_at,
    deadline: Date.parse(streakInfo.streak_deadline),
  };
};

export const DAILY_XP_GOAL = 250;
const STREAK_DAY_GOAL = 5;
const STREAK_5DAY_BONUS = 0;
const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

const updateDeadlineAndReachStreakImmediately = (
  state: StreakInfoKnownState,
  action: ExerciseCompletedAction,
  streakLengthAfterDeadline: number,
): StreakInfoKnownState => {
  const willCross5DayStreak = streakLengthAfterDeadline + 1 === STREAK_DAY_GOAL;
  return {
    ...state,
    incrementedAt: new Date(action.currentTime || Date.now()).toISOString(),
    dailyXp: willCross5DayStreak
      ? action.xpGained + STREAK_5DAY_BONUS
      : action.xpGained,
    lengthInDays: streakLengthAfterDeadline + 1,
    streakGoalMetInSession: true,
    deadline: state.deadline + MILLISECONDS_IN_DAY,
  };
};

const updateDeadline = (
  state: StreakInfoKnownState,
  action: ExerciseCompletedAction,
  streakLengthAfterDeadline: number,
): StreakInfoKnownState => {
  return {
    ...state,
    streakGoalMetInSession: false,
    dailyXp: action.xpGained,
    lengthInDays: streakLengthAfterDeadline,
    deadline: state.deadline + MILLISECONDS_IN_DAY,
  };
};

const handleExerciseCompletedAfterStreakDeadline = (
  state: StreakInfoKnownState,
  action: ExerciseCompletedAction,
): StreakInfoKnownState => {
  const streakWasReachedBeforeDeadline = state.dailyXp >= DAILY_XP_GOAL;
  const willImmediatelyReachStreakAfterDeadline =
    action.xpGained >= DAILY_XP_GOAL;

  const streakLengthAfterDeadline = streakWasReachedBeforeDeadline
    ? state.lengthInDays
    : 0;

  if (willImmediatelyReachStreakAfterDeadline) {
    return updateDeadlineAndReachStreakImmediately(
      state,
      action,
      streakLengthAfterDeadline,
    );
  }

  return updateDeadline(state, action, streakLengthAfterDeadline);
};

const incrementDailyXp = (
  state: StreakInfoKnownState,
  action: ExerciseCompletedAction,
): StreakInfoKnownState => {
  const didMissStreakDeadline = action.currentTime > state.deadline;
  if (didMissStreakDeadline) {
    return handleExerciseCompletedAfterStreakDeadline(state, action);
  }

  const newDailyXp = state.dailyXp + action.xpGained;
  const hasReachedDailyGoal =
    state.dailyXp < DAILY_XP_GOAL && newDailyXp >= DAILY_XP_GOAL;

  const newLengthInDays = hasReachedDailyGoal
    ? state.lengthInDays + 1
    : state.lengthInDays;

  const crossed5dayStreak =
    state.lengthInDays === STREAK_DAY_GOAL - 1 &&
    newLengthInDays === STREAK_DAY_GOAL;

  const newIncrementedAt = hasReachedDailyGoal
    ? new Date(action.currentTime || Date.now()).toISOString()
    : state.incrementedAt;

  return {
    ...state,
    streakGoalMetInSession: state.streakGoalMetInSession || hasReachedDailyGoal,
    incrementedAt: newIncrementedAt,
    dailyXp: crossed5dayStreak ? newDailyXp + STREAK_5DAY_BONUS : newDailyXp,
    lengthInDays: newLengthInDays,
  };
};

function handleShowDailyStreakScreen(state: StreakInfoKnownState) {
  return {
    ...state,
    screenVisible: true,
  };
}

function handleHideDailyStreakScreen(state: StreakInfoKnownState) {
  if (!state.screenVisible) {
    return state;
  }

  return {
    ...state,
    screenVisible: false,
    // Hiding the daily streak screen also dismisses the goal met flag
    // to prevent showing the screen multiple times
    streakGoalMetInSession: false,
  };
}

const streakInfoReducer: Reducer<StreakInfoState> = (
  state = initialState,
  action,
) => {
  if (action.type === BOOT_SUCCEEDED) {
    return storeStreakInfo(state, action.entities.streakInfo);
  }

  if (state.type === StreakInfoType.Unknown || state.version !== 1) {
    return state;
  }

  switch (action.type) {
    case STREAK_INFO_UPDATED:
      return storeStreakInfo(state, action.data);
    case EXERCISE_COMPLETED:
      return incrementDailyXp(state, action);
    case SHOW_DAILY_STREAK_SCREEN:
      return handleShowDailyStreakScreen(state);
    case HIDE_DAILY_STREAK_SCREEN:
      return handleHideDailyStreakScreen(state);
    default:
      return state;
  }
};
export default streakInfoReducer;
