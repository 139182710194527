import { ofType } from 'redux-observable';
import { delay, map } from 'rxjs/operators';

import * as actions from '../actions';

export const epicCheckDailyStreak = (action$: any) =>
  action$.ofType(actions.SET_NEXT_PATH).map(() => actions.checkDailyStreak());

const STREAK_REFRESH_INTERVAL = 3600000; // 1h

export const epicRefreshStreakInfo = (action$: any) =>
  action$.pipe(
    ofType(actions.BOOT_SUCCEEDED, actions.STREAK_INFO_UPDATED),
    delay(STREAK_REFRESH_INTERVAL),
    map(() => actions.refreshDailyStreak()),
  );
