import type { SessionOutput as MultiplexerClientSessionOutput } from '@datacamp/multiplexer-client';

export type SessionOutput =
  | MultiplexerClientSessionOutput
  | {
      payload: string;
      type: 'output';
    };

export const REGISTER_CONSOLE_OUTPUT_CALLBACK =
  'output/REGISTER_CONSOLE_OUTPUT_CALLBACK';

export type RegisterConsoleOutputCallback = {
  callback: (output: SessionOutput[]) => void;
  type: typeof REGISTER_CONSOLE_OUTPUT_CALLBACK;
};

export const registerConsoleOutputCallback = (
  callback: (output: SessionOutput[]) => void,
): RegisterConsoleOutputCallback => ({
  type: REGISTER_CONSOLE_OUTPUT_CALLBACK,
  callback,
});

export const APPEND_CONSOLE_LOG = 'output/APPEND_CONSOLE_LOG';

export type AppendConsoleLog = {
  log: any;
  type: typeof APPEND_CONSOLE_LOG;
};

export const appendConsoleLog = (log: any): AppendConsoleLog => ({
  type: APPEND_CONSOLE_LOG,
  log,
});

export const SET_CONSOLE_OUTPUT = 'output/SET_CONSOLE_OUTPUT';

export type SetConsoleOutput = {
  output: SessionOutput[];
  type: typeof SET_CONSOLE_OUTPUT;
};

export const setConsoleOutput = (
  output: SessionOutput[],
): SetConsoleOutput => ({
  type: SET_CONSOLE_OUTPUT,
  output,
});

export const CLEAR_CONSOLE_OUTPUT = 'output/CLEAR_CONSOLE_OUTPUT';

export type ClearConsoleOutput = {
  type: typeof CLEAR_CONSOLE_OUTPUT;
};

export const clearConsoleOutput = (): ClearConsoleOutput => ({
  type: CLEAR_CONSOLE_OUTPUT,
});

export type OutputActions =
  | AppendConsoleLog
  | ClearConsoleOutput
  | RegisterConsoleOutputCallback
  | SetConsoleOutput;
