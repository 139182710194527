import type { Selector } from 'reselect';
import { createSelector } from 'reselect';

import type { State } from '../../interfaces/State';
import type {
  CodeExplanationInput,
  CodeExplanationState,
} from '../reducers/codeExplanation';

import { selectCourseTitle, selectLanguage } from './course';
import {
  selectExercise,
  selectInstructions,
  selectPEC,
  selectSolution,
} from './exercises';
import { selectUserLanguage } from './user';

export const selectCodeExplanation = (state: State): CodeExplanationState =>
  state.get('codeExplanation');

export const selectExplainCodeButtonEnabled = createSelector(
  selectCodeExplanation,
  (codeExplanationState) =>
    ['initial', 'error'].includes(codeExplanationState.codeExplanation.type),
);

export const selectCodeExplanationInput: Selector<
  State,
  CodeExplanationInput
> = createSelector(
  selectCourseTitle,
  selectLanguage,
  selectUserLanguage,
  selectInstructions,
  selectPEC,
  selectSolution,
  (courseTitle, language, userLanguage, instructions, pec, solution) => ({
    courseTitle,
    language,
    userLanguage,
    instructions,
    pec,
    solution,
  }),
);

export const selectCodeExplanationTrigger = createSelector(
  selectExercise,
  (exercise) => {
    return {
      exerciseId: exercise.get('id'),
      _tag: 'campusUserExplanation',
    } as const;
  },
);
