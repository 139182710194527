import type { State } from '../../interfaces/State';
import type { BackendSessionState } from '../reducers/backendSession';

import {
  selectDatawarehouseSessionIsBroken,
  selectDatawarehouseSessionIsBusy,
  selectDatawarehouseSessionIsReady,
} from './datawarehouseSession';

export const BACKEND_STATUS = {
  BUSY: { code: 'busy', text: 'Session Busy' },
  BROKEN: { code: 'broken', text: 'Session Broken' },
  READY: { code: 'ready', text: 'Workspace Ready' },
  NONE: { code: 'none', text: '' },
} as const;
export type BACKEND_STATUS_CODE = typeof BACKEND_STATUS[keyof typeof BACKEND_STATUS]['code'];

export const selectBackendSession = (
  state: State,
): BackendSessionState | undefined => state.get('backendSession');
export const selectBackendSessionStatusCode = (
  state: State,
): BACKEND_STATUS_CODE | undefined => selectBackendSession(state)?.status.code;
export const isSessionReady = (state: State): boolean =>
  selectBackendSessionStatusCode(state) === BACKEND_STATUS.READY.code &&
  selectDatawarehouseSessionIsReady(state);
export const isSessionBusy = (state: State): boolean =>
  selectBackendSessionStatusCode(state) === BACKEND_STATUS.BUSY.code ||
  selectDatawarehouseSessionIsBusy(state);
export const isSessionBroken = (state: State): boolean =>
  selectBackendSessionStatusCode(state) === BACKEND_STATUS.BROKEN.code ||
  selectDatawarehouseSessionIsBroken(state);

export const isSessionInitializing = (state: State): boolean =>
  selectBackendSession(state)?.isInitSession ||
  selectDatawarehouseSessionIsBusy(state);

export const sessionId = (state: State) =>
  selectBackendSession(state)?.sessionId;
