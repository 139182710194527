import type { Reducer } from '../../interfaces/State';
import { BOOT } from '../actions/boot';

export type LearningMode = 'course' | 'singleChapter' | 'singleExercise';

export type LearningModeState = LearningMode;

const initialState = 'course';

const learningModeReducer: Reducer<LearningModeState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case BOOT: {
      if (action.queryParams.learningMode === 'singleExercise') {
        return 'singleExercise';
      }

      if (action.queryParams.learningMode === 'singleChapter') {
        return 'singleChapter';
      }

      return 'course';
    }
    default:
      return state;
  }
};
export default learningModeReducer;
