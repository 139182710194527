import { constants as videoExerciseConstants } from '@datacamp/video-exercise-core';
import { createSelector } from 'reselect';

import type { State } from '../../interfaces/State';
import type { OnboardingMilestonesState } from '../reducers/onboardingMilestones';

import { selectExerciseType } from './exercises';
import { selectLearningMode } from './learningMode';
import { selectIsUserLoggedIn, selectUserSettings } from './user';

const TOTAL_ONBOARDING_MILESTONES_STEPS = 4;

export const selectOnboardingMilestones = (
  state: State,
): OnboardingMilestonesState => state.get('onboardingMilestones');

export const selectIsOnboardingMilestonesOpen = createSelector(
  selectIsUserLoggedIn,
  selectUserSettings,
  selectOnboardingMilestones,
  selectExerciseType,
  selectLearningMode,
  (
    isUserLoggedIn,
    userSettings,
    onboardingMilestones,
    exerciseType,
    learningMode,
  ) => {
    return (
      isUserLoggedIn &&
      !userSettings.get('has_seen_campus_tour') &&
      learningMode !== 'singleExercise' &&
      onboardingMilestones.isActive &&
      exerciseType !== videoExerciseConstants.exercises.VIDEO_EXERCISE &&
      onboardingMilestones.step < TOTAL_ONBOARDING_MILESTONES_STEPS
    );
  },
);
