import type { Action } from '../actions';
import * as actions from '../actions';

export type WorkspaceSourceCreationInfo =
  | { _tag: 'course'; courseId: number }
  | { _tag: 'template'; templateKey: string };

export type WorkspaceTemplate =
  | {
      _tag: 'template';
      key: string;
      title: string;
    }
  | { _tag: 'course'; courseId: number; title: string };

export type Workspace = {
  id: string;
  source:
    | {
        _tag:
          | 'certificationTemplate'
          | 'course'
          | 'github'
          | 'integration'
          | 'project'
          | 'projectTemplate'
          | 'rdocs'
          | 'workspace';
      }
    | {
        _tag: 'template';
        templateId: number | null;
      };
};

export type WorkspaceNotes =
  | { type: 'error' | 'loading' }
  | { type: 'success'; workspace: Workspace };

export type NotesState = {
  workspaceNotes?: WorkspaceNotes;
  workspaceTemplate: WorkspaceTemplate | null;
};

const initialState: NotesState = {
  workspaceNotes: undefined,
  workspaceTemplate: null,
};

export default (state = initialState, action: Action): NotesState => {
  switch (action.type) {
    case actions.BOOT_SUCCEEDED:
      return {
        ...state,
        workspaceTemplate: action.entities.workspaceTemplate,
      };
    case actions.SET_WORKSPACE_NOTES:
      return {
        ...state,
        workspaceNotes: action.data,
      };
    default:
      return state;
  }
};
