import type { SessionOutput } from '@datacamp/multiplexer-client';
import { constants as videoExerciseConstants } from '@datacamp/video-exercise-core';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isInteger from 'lodash/isInteger';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import { ofType } from 'redux-observable';
import { ignoreElements, tap } from 'rxjs/operators';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import localExercises from '../../../components/StandaloneExercises/localExercises';
import config from '../../../config';
import ApiClient from '../../../helpers/ApiClient';
import fetchExternalExerciseIds from '../../../helpers/fetchExternalExerciseIds';
import {
  getCampusApiClient,
  getMainAppApiClient,
  getTeachClient,
} from '../../../helpers/getClients';
import { isTeachPreview } from '../../../helpers/isTeachPreview';
import { notifyHostApplicationOfExerciseCompletion } from '../../../helpers/learningMode';
import { navigateTo } from '../../../helpers/navigation';
import outputActions from '../../../helpers/outputs';
import type { Action, UpdateActiveTabAction } from '../../actions';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { selectExercise, selectLearningMode } from '../../selectors';
import { submitToMainApp } from '../externalCalls';

const videoExerciseType = videoExerciseConstants.exercises.VIDEO_EXERCISE;

const mainAppClient = getMainAppApiClient();
const campusApiClient = getCampusApiClient();
const teachClient = getTeachClient();
const empty$ = Rx.Observable.empty();

export const getChapterProgress = (action: Action, type: any) =>
  campusApiClient
    .execute(ApiClient.endpoint.getChapterProgress(action), action)
    .filterOnSucceedRequest()
    .map((response: any) => ({
      type,
      data: response.data.body,
    }))
    .catch(() => Rx.Observable.of({ type, data: {} }));

export const epicUpdateExercises = (action$: any, store: any, history: any) =>
  action$
    .ofType(actions.EPIC_UPDATE_EXERCISES)
    .distinctUntilChanged(isEqual)
    .switchMap((action: any) =>
      Rx.Observable.forkJoin(
        getChapterProgress(action, actions.UPDATE_EXERCISES_PROGRESS),
        (isTeachPreview(history.location.pathname)
          ? teachClient
          : campusApiClient
        )
          .execute(
            ApiClient.endpoint.getExercises(
              action.courseRef,
              action.chapterRef,
              {
                language: selectors.selectPathLanguage(store.getState()),
                authorizationHeader: undefined,
              },
            ),
            action,
          )
          .concatMapOnSucceedRequest(async (result: any) => {
            const course = selectors.selectCourse(store.getState()).toObject();
            // same logic in chapter reducer the new current chapter
            const chapter = action.chapters.find(
              (ch: any) =>
                action.chapterRef === ch.get('slug') ||
                Number(action.chapterRef) === Number(ch.get('id')),
            );
            const chapterId = chapter && chapter.get('id');
            if (isInteger(chapterId)) {
              const exercises = cloneDeep(result.data.body);
              Object.assign(
                result.data.body,
                await fetchExternalExerciseIds(course, chapterId, exercises),
              );
            }
            return result;
          })
          .map((result: any) => ({
            ...result,
            injection: action.injection,
          }))
          .concat(
            Rx.Observable.of(
              actions.updateChapter({
                chapterRef: action.chapterRef,
                chapters: action.chapters,
              }),
              // @ts-expect-error ts-migrate(2769) FIXME: Type '(dispatch: any, getState: any) => void' is n... Remove this comment to see the full error message
              actions.updateCurrentExercise({ index: action.exIndex }),
            ),
          )
          .toArray()
          .catch((error: any) => Rx.Observable.of(error)),
      ).mergeMap((results) => Rx.Observable.from(flatten(results))),
    );

const sendIncorrectSubmissionToHeap = (
  action: any,
  state: any,
  exerciseType: string,
  appName: string,
  userId: number,
  exerciseId: number,
): void => {
  const isUserEnabledForAiIncorrectSubmissions = selectors.selectUserIsEnabledForAiIncorrectSubmissions(
    state,
  );
  const actionsHasIncorrectSct = action.results.some(
    (output: any) => output.type === 'sct' && !output.payload.correct,
  );
  if (
    isUserEnabledForAiIncorrectSubmissions &&
    actionsHasIncorrectSct &&
    window.dataLayer
  ) {
    window.dataLayer.push({
      event: 'heap_event',
      heap_event_name: 'Learn - Campus - AI Incorrect Submission Shown',
      heap_event_properties: {
        app_id: appName,
        event_category: 'learning',
        identity: userId,
        exercise_id: exerciseId,
        exercise_type: exerciseType,
      },
    });
  }
};

/**
 * Filter the SCT output if there are errors in the output, so we only show the AI Error Explanation tab
 * Only do this if the user is enabled for the AI Error Explanation
 * Also pushed the event to the dataLayer if the AI Error Explanation is shown
 */
const filterResults = ({
  eventProperties,
  isUserEnabledForAiErrorExplanation,
  results,
}: {
  eventProperties: { [key: string]: string };
  isUserEnabledForAiErrorExplanation: boolean;
  results: SessionOutput[];
}) => {
  const actionHasErrors = results.some(
    (output) =>
      output.type === 'error' ||
      output.type === 'r-error' ||
      // @ts-expect-error update multiplexer-client to 5.0.0 and update type to fix this
      output.type === 'parse-error' ||
      // @ts-expect-error update multiplexer-client to 5.0.0 and update type to fix this
      output.type === 'try-error',
  );
  const shouldFilterResults =
    isUserEnabledForAiErrorExplanation && actionHasErrors;
  if (shouldFilterResults) {
    const filteredResults = results.filter((output) => {
      const isCorrectSct = output.type === 'sct' && output.payload.correct;
      return output.type !== 'sct' || isCorrectSct;
    });
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'heap_event',
        heap_event_name: 'Learn - Campus - AI Error Explanation Shown',
        heap_event_properties: eventProperties,
      });
    }
    return filteredResults;
  }
  return results;
};

export const epicConsoleCode = (
  defaultAction$: any,
  _store: any,
  _history: any,
  epic$: any,
) => {
  const lazyEpic = (action$: any, store: any) =>
    action$
      .ofType(actions.RESULT_EXERCISE)
      .filter(() => !selectors.selectShouldStopBackendManager(store.getState()))
      .concatMap((action: any) => {
        const state = store.getState();
        const { appName } = config;
        const exerciseType = selectors.selectExercise(state).get('type');
        const userId = selectors.selectUserSettings(state).get('id');
        const exerciseId =
          selectors.selectCurrentSubExercise(state).get('id') ||
          selectors.selectExercise(state).get('id');
        const language = selectors.selectLanguage(state);
        const isUserEnabledForAIAssistant = selectors.selectUserIsEnabledForAiErrorExplanation(
          state,
        );
        const eventProperties = {
          app_id: appName,
          event_category: 'learning',
          identity: userId,
          exercise_id: exerciseId,
          exercise_type: exerciseType,
        };
        const filteredResults = filterResults({
          eventProperties,
          isUserEnabledForAiErrorExplanation: isUserEnabledForAIAssistant,
          results: action.results,
        });
        sendIncorrectSubmissionToHeap(
          action,
          state,
          selectors.selectExerciseOrSubExType(state),
          appName,
          userId,
          exerciseId,
        );
        return Rx.Observable.from<SessionOutput>(
          filteredResults,
        ).concatMap((output) => outputActions(language, output, exerciseType));
      })
      // stop doing any update
      .takeUntil(
        action$.ofType(
          actions.NO_SESSION,
          actions.STOP_BACKEND_SESSION,
          actions.EPIC_START_SESSION,
        ),
      );

  return (
    defaultAction$
      .ofType(actions.EPIC_START_SESSION)
      // add epic lazily to not mix an output with another ex
      // for ex: the output of ex A appears in the console
      //         of the ex B because the user went to the next ex
      .do(() => epic$.next(lazyEpic))
      .concatMapTo(empty$)
  );
};

export const epicCompletingSubExercise = (
  action$: any,
  store: any,
  history: any,
) =>
  action$
    .ofType(actions.EPIC_COMPLETING_SUB_EXERCISE)
    .concatMap((action: any) =>
      submitToMainApp(
        actions.submitToMainApp(action.submitSettings),
        store,
        history,
      ).merge(
        Rx.Observable.of(
          actions.completeSubExercise({
            ...action,
            isLastExercise: selectors
              .selectNextSubExercise(store.getState())
              .isEmpty(),
          }),
        ),
      ),
    );

export const epicCompleteSubExercise = (action$: any, store: any) =>
  action$.ofType(actions.COMPLETE_SUB_EXERCISE).concatMap((action: any) => {
    const state = store.getState();
    const subExercises = selectors.selectSubExercises(state);
    // exercise completed
    if (
      !subExercises.isEmpty() &&
      action.isLastExercise &&
      subExercises.reduce(
        (acc: any, subEx: any) =>
          acc && subEx.getIn(['completed', 'completed']),
        true,
      )
    ) {
      // avoid submitting two times the result to the main app
      return Rx.Observable.of(
        actions.completeExercise({ ...action, submitSettings: {} }),
      );
    }
    return empty$;
  });

export const epicCompleteExercise = (action$: any, store: any, history: any) =>
  action$.ofType(actions.COMPLETE_EXERCISE).concatMap((action: any) => {
    const state = store.getState();
    const progress = selectors.selectGlobalUserProgress(state);
    const shouldSeeRating = selectors.selectShouldSeeChapterRating(state);
    const nextPath = selectors.selectNeighborsExercises(state).nextExercise;
    const exType = selectors.selectExercise(state).get('type');
    const completedFirstTime =
      (progress.chapter.completed || progress.course.completed) &&
      shouldSeeRating;
    const chapterId = selectors.selectChapterId(state);
    const courseId = selectors.selectCourse(state).get('id');

    const submitToMainApp$ = isEmpty(action.submitSettings)
      ? Rx.Observable.of()
      : submitToMainApp(
          actions.submitToMainApp(action.submitSettings),
          store,
          history,
        );
    let obs$ = Rx.Observable.of();
    if (completedFirstTime) {
      obs$ = obs$.concat(
        submitToMainApp$,
        mainAppClient.execute(
          ApiClient.endpoint.window({
            type: 'chapter_completed',
            chapter_id: chapterId,
            course_id: courseId,
          }),
          actions.epicGotWindowResponse(),
        ),
      );
    } else if (exType !== videoExerciseType || action.show) {
      obs$ = obs$.merge(
        submitToMainApp$,
        Rx.Observable.of(actions.shouldShowWindow({ type: 'none' })),
      );
    }

    // for VideoExercise, only show overlay when at the end of chapter of course
    if (exType === videoExerciseType && !action.show) {
      if (completedFirstTime) {
        return obs$
          .concat(
            Rx.Observable.of(
              actions.completeExercise({ ...action, show: true }),
            ),
          )
          .catch(() => empty$);
      }
      obs$ = obs$.merge(submitToMainApp$);
      navigateTo(history, nextPath);
    }

    return obs$.catch(() => empty$);
  });

export const epicSubmitExerciseRating = (
  action$: any,
  store: any,
  history: any,
) =>
  action$.ofType(actions.EPIC_SUBMIT_EXERCISE_RATING).flatMap((action: any) => {
    const exerciseId = selectors.selectExercise(store.getState()).get('id');
    return campusApiClient
      .execute(
        ApiClient.endpoint.submitExerciseRating(exerciseId, {
          rating: action.rating,
          extraInfo: action.extraInfo,
        }),
      )
      .do(() => {
        if (!isNil(action.nextPath)) {
          navigateTo(history, action.nextPath);
        }
      })
      .catch(() => empty$);
  });

export const epicSubmitChapterRating = (action$: any, store: any) =>
  action$.ofType(actions.EPIC_SUBMIT_CHAPTER_RATING).flatMap((action: any) => {
    const chapterId = selectors.selectChapter(store.getState()).get('id');
    return campusApiClient
      .execute(
        ApiClient.endpoint.submitRating(chapterId, {
          rating: action.rating,
          extraInfo: action.extraInfo,
        }),
      )
      .concat(action.onFinish())
      .catch(() => empty$);
  });

const shouldShowInModal = ({
  action,
  store,
  validActiveKey,
}: {
  action: UpdateActiveTabAction;
  store: any;
  validActiveKey: string;
}): boolean => {
  if (action.activeKey !== validActiveKey) {
    return false;
  }
  const state = store.getState();
  if (selectors.selectLanguage(state) === 'sql') {
    return true;
  }
  const exType = selectors.selectExercise(state).get('type');
  if (localExercises(exType) !== undefined) {
    return true;
  }
  switch (exType) {
    case 'TabConsoleExercise':
      return true;
    case 'MarkdownExercise':
      return true;
    default:
      return false;
  }
};

export const epicShowSlideInModal = (action$: any, store: any) =>
  action$
    .ofType(actions.UPDATE_ACTIVE_TAB)
    .filter((action: UpdateActiveTabAction) =>
      shouldShowInModal({ action, store, validActiveKey: 'slides' }),
    )
    .mapTo(actions.showModal({ modal: selectors.MODAL_TYPE.SLIDES }));

export const epicUpdateConsoleCode = (action$: any) =>
  action$
    .ofType(actions.UPDATE_CONSOLE_CODE)
    .combineLatest(
      action$.ofType(actions.REGISTER_CONSOLE_OUTPUT_CALLBACK),
      (consoleCodeAction: any, callbackAction: any) => ({
        ...consoleCodeAction,
        callback: callbackAction.callback,
      }),
    )
    .do((action: any) => {
      // We call the callback manually instead of the mux calling it to simulate the command echo
      action.callback([action.output]);
    })
    .concatMapTo([]);

export const tryNavigateToNextExercise = ({
  history,
  nextPath: optionalNextPath,
  state,
}: any) => {
  const windowType = selectors.selectChapterWindow(state).get('type');
  const ltiRequestStatus = selectors.selectLtiStatus(state);
  const nextPath = isNil(optionalNextPath)
    ? selectors.selectNextPath(state)
    : optionalNextPath;

  switch (ltiRequestStatus) {
    case 'failure':
      return [
        actions.showModal({
          modal: {
            ...selectors.MODAL_TYPE.LTI_FAILURE,
          },
        }),
      ];
    case 'pending':
      return [];
    case 'inactive':
    case 'success':
    default: {
      if (windowType === 'screen') {
        return [actions.showWindow({ show: true })];
      }

      navigateTo(history, nextPath);
      return [];
    }
  }
};

export const epicSetLtiStatus = (action$: any, store: any, history: any) =>
  action$.ofType(actions.SET_LTI_STATUS).concatMap(() => {
    const state = store.getState();

    if (selectors.selectIsNavigatingToNextExercise(state)) {
      return tryNavigateToNextExercise({
        state,
        history,
      });
    }
    return [];
  });

const getNextPath = (state: any) =>
  selectors.selectNeighborsExercises(state).nextExercise;

export const epicNextInternalExercise = (
  action$: any,
  store: any,
  history: any,
) =>
  action$.ofType(actions.NEXT_INTERNAL_EXERCISE).concatMap(() => {
    const state = store.getState();

    if (selectLearningMode(state) === 'singleExercise') {
      const exerciseId = selectExercise(state).get('id');
      notifyHostApplicationOfExerciseCompletion(exerciseId);
      return Rx.Observable.empty();
    }

    const nextPath = getNextPath(state);

    return [
      actions.setNextPath(nextPath),
      ...tryNavigateToNextExercise({
        state,
        history,
        nextPath,
      }),
    ];
  });

export const epicExerciseCompleted = (action$: any, store: any) => {
  return action$.pipe(
    ofType(actions.EXERCISE_COMPLETED),
    tap(() => {
      const state = store.getState();
      if (selectLearningMode(state) !== 'singleExercise') {
        return;
      }
      const exercise = selectors.selectExercise(state);
      if (exercise.get('type') !== videoExerciseType) {
        return;
      }
      notifyHostApplicationOfExerciseCompletion(exercise.get('id'));
    }),
    ignoreElements(),
  );
};
