import type { Thunk } from '../../interfaces/State';
import { MODAL_TYPE } from '../selectors/settings';
import { selectIsUserNotLoggedIn } from '../selectors/user';

import { epicSubmitCode } from './backendSession';
import { showModal } from './settings';

export const executeCode = (
  codeSettings: Parameters<typeof epicSubmitCode>[0],
): Thunk => (dispatch, getState) => {
  const state = getState();
  const isUserNotLoggedIn = selectIsUserNotLoggedIn(state);

  if (isUserNotLoggedIn) {
    dispatch(showModal({ modal: MODAL_TYPE.LOGIN }));
    return;
  }

  dispatch(epicSubmitCode(codeSettings));
};
