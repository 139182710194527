import { mainApp } from '../../../helpers/api/clients';
import { normalize } from '../../../helpers/cms/normalize';
import { REQUEST_STATUS } from '../../selectors';

import createError from './createError';

export type LanguageMap = {
  [key: string]: string[];
};

type TranslatedLanguages = 'de-DE' | 'es-ES' | 'fr-FR' | 'pt-BR';

export type CourseTranslation = {
  course_id: number;
  created_at: string; // ISO date string
  id: number;
  in_sync: boolean;
  language: TranslatedLanguages;
  status: 'development' | 'disabled' | 'live';
  translated_course_id: number;
  updated_at: string; // ISO date string
};

export default async ({
  preFetchedData,
}: {
  preFetchedData: Map<string, any>;
}): Promise<LanguageMap | null> => {
  const preFetchedDataRequest = preFetchedData.get('translatedCourses');
  if (preFetchedDataRequest.get('status') === REQUEST_STATUS.SUCCESS) {
    return Promise.resolve(preFetchedDataRequest.get('data'));
  }

  const mainAppTranslatedCourses: CourseTranslation[] = await mainApp
    .getTranslatedCourses()
    .then((response) => {
      return normalize(response.body);
    })
    .catch((error) => {
      return Promise.reject(
        createError({ message: `Failed to get translated courses: ${error}` }),
      );
    });

  const translatedCourses = mainAppTranslatedCourses.reduce<LanguageMap>(
    (acc, { course_id, language, status }) => {
      if (status !== 'live') {
        return acc;
      }
      const locale = language.split('-')[0].toLowerCase();

      if (acc[course_id] === undefined) {
        acc[course_id] = [locale];
      } else if (!acc[course_id].includes(locale)) {
        acc[course_id] = [...acc[course_id], locale];
      }
      return acc;
    },
    {},
  );

  return translatedCourses ?? null;
};
