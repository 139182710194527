import { fromJS, Map as hashMap } from 'immutable';
import isBoolean from 'lodash/isBoolean';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import store from 'store';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/universal-rx-request` if i... Remove this comment to see the full error message
// eslint-disable-next-line no-restricted-imports
import { getStatus, STATUS } from 'universal-rx-request';

import * as actions from '../actions';
import type { ModalCode } from '../selectors/settings';
import { MODAL_TYPE } from '../selectors/settings';

// This is already exported for the IframeModal but probably needs to be validated more and put in a Map / Record type
export type ModalSettingsShape = {
  code: ModalCode;
  errorMessage?: string;
  extraClass?: string;
  meta?: {
    height: number;
    width: number;
  };
  onExit?: () => void;
  show: boolean;
  url: string;
};

const CAMPUS_THEME_STORAGE_KEY = 'campus_theme';

const initialState = hashMap({
  uiTheme: store.get(CAMPUS_THEME_STORAGE_KEY) === 'LIGHT' ? 'LIGHT' : 'DARK',
  feedbackRatingStatus: 'NONE',
  mobileView: 'CONTEXT',
});

/**
 * Reducer that switches between light and dark UI themes.
 * Valid theme constants are DARK and LIGHT
 * @param {*} state
 */
function toggleUITheme(state: any) {
  const newTheme = state.get('uiTheme') === 'LIGHT' ? 'DARK' : 'LIGHT';
  store.set(CAMPUS_THEME_STORAGE_KEY, newTheme);
  return state.set('uiTheme', newTheme);
}

const feedbackRatingStatusMapping = {
  [STATUS.FETCHING]: 'SAVING',
  [STATUS.ERROR]: 'ERROR',
  [STATUS.SUCCESS]: 'SAVED',
};

export default (state = initialState, action = {}) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
  switch (action.type) {
    case actions.BOOT:
      return state.set('uiTheme', initialState.get('uiTheme') as string);
    case actions.REMOVE_MODAL: {
      if (
        [MODAL_TYPE.LOGIN.code].indexOf(state.getIn(['modal', 'code'])) > -1
      ) {
        return state;
      }

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'force' does not exist on type '{}'.
      if (action.force || state.get('modal') == null) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        return state.set('modal', null).set('isExerciseActive', true);
      }

      return (
        state
          .setIn(['modal', 'show'], false)
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
          .set('isExerciseActive', true)
      );
    }
    case actions.SHOW_MODAL: {
      let newState = state;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'modal' does not exist on type '{}'.
      if (newState.getIn(['modal', 'code']) === action.modal.code) {
        newState = newState.update('modal', (modal) =>
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'mergeDeep' does not exist on type 'strin... Remove this comment to see the full error message
          modal.mergeDeep({ ...action.modal, show: true }),
        );
      } else {
        newState = newState.set(
          'modal',
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'modal' does not exist on type '{}'.
          fromJS({ ...action.modal, show: true }),
        );
      }
      switch (newState.getIn(['modal', 'code'])) {
        case MODAL_TYPE.ISSUE_REPORTER.code:
          newState = newState.setIn(['modal', 'submitRequestState'], null);
          break;
        case MODAL_TYPE.FEEDBACK_REPORTER.code:
          newState = newState.setIn(['modal', 'submitRequestState'], null);
          break;
        default:
          break;
      }
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
      return newState.set('isExerciseActive', false);
    }
    case actions.UPDATE_MODAL_SETTINGS: {
      let newState = state;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'settings' does not exist on type '{}'.
      if (isBoolean(action.settings.show)) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
        newState = newState.set('isExerciseActive', !action.settings.show);
      }
      if (isNil(newState.get('modal'))) {
        return newState;
      }
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Map<unknown, unknown>' is not assignable to ... Remove this comment to see the full error message
      return newState.update('modal', (modal = hashMap()) =>
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'mergeDeep' does not exist on type 'strin... Remove this comment to see the full error message
        modal.mergeDeep(action.settings),
      );
    }
    case getStatus(actions.EPIC_SUBMIT_FEEDBACK, STATUS.FETCHING):
    case getStatus(actions.EPIC_SUBMIT_FEEDBACK, STATUS.ERROR):
    case getStatus(actions.EPIC_SUBMIT_FEEDBACK, STATUS.SUCCESS):
      return state.set(
        'feedbackRatingStatus',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'requestStatus' does not exist on type '{}'.
        feedbackRatingStatusMapping[action.requestStatus],
      );
    case actions.SHOW_HINT:
    case actions.UPDATE_FEEDBACK_TAB:
    case actions.UPDATE_FEEDBACK_MESSAGE:
    case actions.UPDATE_FEEDBACK_MESSAGE_WITH_ASSISTANT:
    case actions.UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX:
      return state
        .set('feedbackRatingStatus', 'NONE')
        .set('mobileView', 'CONTEXT');
    case actions.SHOW_SOLUTION:
      return state
        .set('feedbackRatingStatus', 'NONE')
        .set('mobileView', 'EXERCISE');
    case getStatus(actions.EPIC_SUBMIT_ISSUE, STATUS.FETCHING):
    case getStatus(actions.EPIC_SUBMIT_ISSUE, STATUS.ERROR):
    case getStatus(actions.EPIC_SUBMIT_ISSUE, STATUS.SUCCESS):
      if (isNil(state.get('modal'))) {
        return state;
      }
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Map<unknown, unknown>' is not assignable to ... Remove this comment to see the full error message
      return state.update('modal', (modal = hashMap()) =>
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'mergeDeep' does not exist on type 'strin... Remove this comment to see the full error message
        modal.mergeDeep({ submitRequestState: action.requestStatus }),
      );
    case actions.EPIC_CLEAR_BACKEND_CLIENT_SETTINGS:
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
      return state.set('shouldStopBackendManager', true);
    case actions.INCREMENT_SHORTCUT_USAGE:
      return state.update(
        'shortcutsUsage',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '0' is not assignable to type 'string'.
        (shortcutsUsage = 0) => shortcutsUsage + 1,
      );
    case actions.CLOSE_SHORTCUT_FRAME:
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
      return state.set('shouldCloseShortcutFrame', true);
    case actions.TOGGLE_UI_THEME:
      return toggleUITheme(state);
    case actions.SET_MOBILE_VIEW:
      // @ts-expect-error ts(2339) FIXME: Property 'selectedView' does not exist on type '{}'.
      return state.set('mobileView', action.selectedView);
    case actions.COMPLETE_EXERCISE:
      // Cases when we want the mobile UI to switch to the CONTEXT view
      return state.set('mobileView', 'CONTEXT');
    default:
      return state;
  }
};
