// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/datacamp__dc-languages-con... Remove this comment to see the full error message
import languagesConfig, { getTabTitle } from '@datacamp/dc-languages-config';
import { List as list, Map as hashMap } from 'immutable';
import get from 'lodash/get';

import { isTabOrBulletConsoleEx } from '../../../helpers/ComposedConsoleExercise';
import type { Action } from '../../actions';
import * as actions from '../../actions';
import { getCanSubmitExercise } from '../../selectors';

import {
  activate as activateFeedbackMessages,
  complete as completeExercise,
  markActiveFeedbackAsRated,
  update as updateFeedbackMessages,
  updateSolutionFeedback,
  updateWithAssistantFeedbackMessage,
} from './feedbackMessages';
import { showHint, updateSolutionTab } from './hintAndSolution';
import { setCurrentXp } from './initialSubExerciseState';

// helpers to select specific fields starting from an exercise
const getSubExs = (exercise: any) => exercise.get('subexercises') || list();

const getCurrentSubExerciseKey = (exercise: any) =>
  getSubExs(exercise).findKey((ex: any) => ex.get('active')) || 0;
const getCurrentSubExercise = (exercise: any) =>
  exercise.getIn(['subexercises', getCurrentSubExerciseKey(exercise)]) ||
  hashMap();
const updateSubExercises = (exercise: any, fn: any) =>
  exercise.update('subexercises', (subExs = list()) => fn(subExs));
const updateCurrentSubExercise = (exercise: any, fn: any) =>
  exercise.updateIn(
    ['subexercises', getCurrentSubExerciseKey(exercise)],
    (subEx = hashMap()) => fn(subEx),
  );
const isNextSubEx = (exercise: any) =>
  Boolean(getSubExs(exercise).get(getCurrentSubExerciseKey(exercise) + 1));
const updateSubExTab = (subExs: any, key: any) =>
  subExs
    .map((subEx: any) => subEx.set('active', false))
    .update(key, (subEx: any) =>
      subEx.set('active', true).set('unlocked', true),
    );

const switchTab = (exercise: any, activeKey: any) => {
  const isCurrentSubEx =
    String(getCurrentSubExerciseKey(exercise)) !== activeKey;
  let exerciseUpdated = exercise;
  if (isCurrentSubEx) {
    // Sub exercises seem to use solutionReadOnly instead of solution for the file
    const solutionTabTitle = getTabTitle(
      'solutionReadOnly',
      exercise.get('language'),
      exercise.get('type'),
    );
    const solutionTabKey = `solution/${solutionTabTitle}`;

    exerciseUpdated = exerciseUpdated
      .removeIn(['user', 'editorTabs', solutionTabKey])
      .updateIn(['user', 'editorTabs'], (editorTabs: any) =>
        editorTabs.map((editorTab: any) =>
          editorTab.removeIn(['props', 'lineErrors']),
        ),
      );
  }

  const newActiveSubEx = getSubExs(exercise).get(activeKey);
  if (
    newActiveSubEx.get('isSolutionShown') &&
    !isTabOrBulletConsoleEx(exercise.get('type'))
  ) {
    exerciseUpdated = exercise.update('user', (user: any) =>
      updateSolutionTab(
        user,
        newActiveSubEx,
        exercise.get('language'),
        'solutionReadOnly',
      ),
    );
  }
  // Remove code explanation tab when switching to another sub exercise
  const consoleTabsCategory =
    exercise.get('language') === 'sql' ? 'consoleSqlTabs' : 'consoleTabs';
  exerciseUpdated = exerciseUpdated.removeIn([
    'user',
    consoleTabsCategory,
    'codeExplanation',
  ]);
  return updateSubExercises(exerciseUpdated, (subExs: any) =>
    updateSubExTab(subExs, activeKey),
  );
};

const subActiveExReducer = (subEx: any, exercise: any, action: Action) => {
  switch (action.type) {
    case actions.UPDATE_FEEDBACK_TAB:
      return activateFeedbackMessages(subEx, action);
    case actions.COMPLETE_SUB_EXERCISE:
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
      return completeExercise(subEx, action, subEx).set(
        'xpWon',
        subEx.get('xpToWin'),
      );
    case actions.UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX:
      return updateFeedbackMessages(subEx, action, subEx);
    case actions.UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX_WITH_ASSISTANT:
      return updateWithAssistantFeedbackMessage(subEx, action.error);
    case actions.EPIC_SUBMIT_FEEDBACK:
      return markActiveFeedbackAsRated(subEx);
    case actions.SET_HINT_SHOWN:
      return subEx.set('isHintShown', action.isHintShown);
    case actions.SHOW_HINT:
      return showHint(subEx, subEx, action).update(
        'xpToWin',
        (xpToWin: any) => xpToWin - action.xp,
      );
    case actions.SHOW_SOLUTION:
      return subEx
        .set('isSolutionShown', true)
        .update('xpToWin', (xpToWin: any) => xpToWin - action.xp);
    case actions.EPIC_SUBMIT_CODE:
      if (
        action.settings.command === 'submit' &&
        getCanSubmitExercise(exercise, subEx)
      ) {
        return subEx.set('last_attempt', action.settings.code);
      }
      return subEx;
    case actions.SET_MCE_CHOICES_FOCUS:
      return subEx.set('choicesFocused', action.focused);
    case actions.COPY_SOLUTION:
      return subEx.updateIn(
        ['tabs', 'script', 'code'],
        // hack to force the update of the script tab
        (defaultCode: any) =>
          action.code === defaultCode ? `${defaultCode} ` : action.code,
      );
    default:
      return subEx;
  }
};

export default (defaultExercise: any, action: Action) => {
  let exercise = updateCurrentSubExercise(defaultExercise, (subEx: any) =>
    subActiveExReducer(subEx, defaultExercise, action),
  );
  switch (action.type) {
    case actions.COMPLETE_SUB_EXERCISE: {
      exercise = setCurrentXp(exercise).updateIn(
        ['user', 'editorTabs'],

        (editorTabs: any) =>
          editorTabs.map((editorTab: any) =>
            editorTab.removeIn(['props', 'lineErrors']),
          ),
      );
      if (isNextSubEx(exercise)) {
        const language = exercise.get('language');
        const config = get(languagesConfig, language, {});
        const tabTitle = getTabTitle(
          config.editorPrefixTitle,
          language,
          exercise.get('type'),
        );
        const scriptTabKey = `files/${tabTitle}`;

        const activeKey = getCurrentSubExerciseKey(exercise) + 1;
        const sampleCode = exercise
          .get('subexercises')
          .get(activeKey)
          .get('sample_code');
        const codeForSubEx = sampleCode || '';

        exercise = exercise.setIn(
          ['subexercises', activeKey, 'tabs', scriptTabKey, 'code'],
          codeForSubEx,
        );

        return switchTab(exercise, activeKey);
      }
      return exercise;
    }
    case actions.UPDATE_ACTIVE_INSTRUCTION_TAB:
      return switchTab(exercise, action.activeKey);
    case actions.CHOOSE_ANSWER:
      return updateSubExercises(exercise, (subExs: any) =>
        subExs.setIn([action.index, 'multipleChoiceAnswer'], action.choice),
      );
    case actions.SHOW_SOLUTION: {
      // We need to delete the default tab created in the exercise reducer to add the "readonly" one.
      const solutionTabTitle = getTabTitle(
        'solution',
        exercise.get('language'),
        exercise.get('type'),
      );
      const solutionTabKey = `solution/${solutionTabTitle}`;

      switch (exercise.get('type')) {
        case 'BulletConsoleExercise':
        case 'TabConsoleExercise':
          return updateCurrentSubExercise(exercise, (subEx: any) =>
            updateSolutionFeedback(subEx, subEx.get('solution', '')),
          ).removeIn(['user', 'editorTabs', solutionTabKey]);
        default:
          return exercise
            .update('user', (user: any) =>
              updateSolutionTab(
                user,
                getCurrentSubExercise(exercise),
                exercise.get('language'),
                'solutionReadOnly',
              ),
            )
            .removeIn(['user', 'editorTabs', solutionTabKey]);
      }
    }
    case actions.SAVE_CODE:
      return exercise.update('subexercises', (subExs: any) =>
        subExs.map((subEx: any) => {
          if (action.id === subEx.get('id')) {
            return subEx.setIn(['tabs', action.tabKey, 'code'], action.code);
          }
          return subEx;
        }),
      );
    default:
      return exercise;
  }
};
