// eslint-disable-next-line no-shadow
export enum StreakInfoType {
  Known = 'StreakKnown',
  Unknown = 'StreakUnknown',
}

export type StreakWeekItem = {
  date: string;
  status: 'frozen' | 'met' | 'unmet';
  weekday: 'Fri' | 'Mon' | 'Sat' | 'Sun' | 'Thu' | 'Tue' | 'Wed';
};

export type StreakInfo = {
  freezes_available: number;
  streak: {
    created_at?: string;
    days: number;
    last_incremented_at?: string;
  };
  streak_deadline: string;
  streak_goal_met: boolean;
  streak_week_view: StreakWeekItem[];
};

export type StreakInfoUnknownState = {
  type: StreakInfoType.Unknown;
};

type StreakInfoStateCommon = {
  dailyXp: number;
  screenVisible: boolean;
  streakGoalMetInSession: boolean;
  type: StreakInfoType.Known;
};

export type StreakInfoKnownStateV2 = StreakInfoStateCommon & {
  current: StreakInfo;
  freezesAwardedInSession: number;
  next: StreakInfo | null;
  streakGoal: number;
  version: 2;
};

export type StreakInfoKnownState = StreakInfoStateCommon & {
  createdAt?: string;
  deadline: number;
  incrementedAt?: string;
  lengthInDays: number;
  version: 1;
};

export type StreakInfoState =
  | StreakInfoKnownState
  | StreakInfoKnownStateV2
  | StreakInfoUnknownState;
