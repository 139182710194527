import { constants as videoExerciseConstants } from '@datacamp/video-exercise-core';
import { List as list } from 'immutable';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import { createSelector } from 'reselect';

const videoExerciseType = videoExerciseConstants.exercises.VIDEO_EXERCISE;

export const selectExercises = (state: any) =>
  state.getIn(['exercises', 'all'], list());

export const selectNextExternalExerciseIdsByExerciseTypes = createSelector(
  selectExercises,
  (state) => state.getIn(['exercises', 'current']),
  (exercises, exerciseIndex) => {
    return exercises
      .slice(exerciseIndex + 1)
      .groupBy((exercise: any) => exercise.get('type'))
      .map((exercisesByType: any) =>
        exercisesByType.map((exercise: any) => exercise.get('externalId')),
      );
  },
);

export const selectCurrentChapterNbExercises = (state: any) =>
  selectExercises(state).size;

export const selectPrecedingVideoExercise = (state: any) => {
  const exerciseIndex = state.getIn(['exercises', 'current']);
  const exerciseType = selectExercises(state).getIn(
    [exerciseIndex, 'type'],
    null,
  );

  if (exerciseType === videoExerciseType) {
    return null;
  }

  // Find the last VideoExercise, that is before the current exercise
  const precedingVideoExercise = selectExercises(state)
    .take(exerciseIndex)
    .findLast((exercise: any) => exercise.get('type') === videoExerciseType);

  return isNil(precedingVideoExercise) ? null : precedingVideoExercise.toJS();
};

export const selectCanRateChapter = (state: any) =>
  state.getIn(['exercises', 'canRateChapter']);
