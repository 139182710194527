import type { Map } from 'immutable';

import { collabApi } from '../../../helpers/api/clients';
import type { Technology, TechnologyId } from '../../../helpers/technology';
import { technologyById } from '../../../helpers/technology';
import type { WorkspaceTemplate } from '../../reducers/notes';
import { REQUEST_STATUS } from '../../selectors/preFetchedData';

const technologiesWithBoilerplateTemplate: Technology[] = [
  'R',
  'Python',
  'SQL',
];

export default async ({
  courseAsPromise,
  preFetchedData,
}: {
  courseAsPromise: Promise<{
    id: number;
    technology_id: number;
    title: string;
  }>;
  preFetchedData: Map<string, any>;
}): Promise<WorkspaceTemplate | null> => {
  const preFetchedDataRequest = preFetchedData.get('workspaceTemplate');
  switch (preFetchedDataRequest.get('status')) {
    case REQUEST_STATUS.SUCCESS:
      return Promise.resolve(preFetchedDataRequest.get('data'));
    default: {
      try {
        const course = await courseAsPromise;
        try {
          const { body } = await collabApi.getWorkspaceTemplate({
            courseId: course.id,
          });
          return {
            _tag: 'template',
            ...body,
          };
        } catch (e) {
          if (
            technologiesWithBoilerplateTemplate.includes(
              technologyById[course.technology_id as TechnologyId],
            )
          ) {
            return {
              _tag: 'course',
              courseId: course.id,
              title: `Course Notes: ${course.title}`,
            };
          }

          return null;
        }
      } catch (error) {
        return null;
      }
    }
  }
};
