import { collabApi } from '../../helpers/api/clients';
import type { Dispatch } from '../../interfaces/State';
import unreachable from '../../interfaces/unreachable';
import type {
  Workspace,
  WorkspaceNotes,
  WorkspaceSourceCreationInfo,
  WorkspaceTemplate,
} from '../reducers/notes';

export const SET_WORKSPACE_NOTES = 'SET_WORKSPACE_NOTES';
export type SetWorkspaceNotesAction = {
  data: WorkspaceNotes;
  type: typeof SET_WORKSPACE_NOTES;
};
const setWorkspaceNotes = ({ data }: Omit<SetWorkspaceNotesAction, 'type'>) =>
  ({
    type: SET_WORKSPACE_NOTES,
    data,
  } as const);

const getWorkspaceSourceFromWorkspaceTemplate = ({
  workspaceTemplate,
}: {
  workspaceTemplate: WorkspaceTemplate;
}): WorkspaceSourceCreationInfo => {
  switch (workspaceTemplate._tag) {
    case 'course':
      return {
        _tag: 'course',
        courseId: workspaceTemplate.courseId,
      };
    case 'template':
      return {
        _tag: 'template',
        templateKey: workspaceTemplate.key,
      };
    default:
      return unreachable(workspaceTemplate);
  }
};

export const getWorkspaceNotes = ({
  courseSlug,
  workspaceTemplate,
}: {
  courseSlug: string;
  workspaceTemplate: WorkspaceTemplate;
}) => async (dispatch: Dispatch) => {
  dispatch(setWorkspaceNotes({ data: { type: 'loading' } }));
  try {
    const workspace: Workspace = (
      await collabApi.getOwnedWorkspaceBySource({
        source: getWorkspaceSourceFromWorkspaceTemplate({ workspaceTemplate }),
        courseSlug,
      })
    ).body;

    dispatch(
      setWorkspaceNotes({
        data: { type: 'success', workspace },
      }),
    );
  } catch (error) {
    dispatch(setWorkspaceNotes({ data: { type: 'error' } }));
  }
};
