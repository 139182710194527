export const UPDATE_CHAPTER_PROGRESS = 'course/UPDATE_CHAPTER_PROGRESS';

export const UPDATE_COURSE_PROGRESS = 'course/UPDATE_COURSE_PROGRESS';
export type UpdateCourseProgressAction = {
  data: any;
  type: typeof UPDATE_COURSE_PROGRESS;
};
export const updateCourseProgress = ({
  data,
}: any): UpdateCourseProgressAction => ({
  type: UPDATE_COURSE_PROGRESS,
  data,
});

export const EPIC_GET_EXERCISES_FROM_CHAPTER =
  'course/EPIC_GET_EXERCISES_FROM_CHAPTER';
export type EpicGetExercisesFromChapterAction = {
  chapterRef: string;
  courseRef: string;
  type: typeof EPIC_GET_EXERCISES_FROM_CHAPTER;
};
export const epicGetExercisesFromChapter = ({
  chapterRef,
  courseRef,
}: any): EpicGetExercisesFromChapterAction => ({
  type: EPIC_GET_EXERCISES_FROM_CHAPTER,
  chapterRef,
  courseRef,
});

export const UPDATE_EXERCISES_IN_CHAPTER = 'course/UPDATE_EXERCISES_IN_CHAPTER';
export type UpdateExercisesInChapterAction = {
  chapterRef: string;
  exercises: any;
  progress: any;
  type: typeof UPDATE_EXERCISES_IN_CHAPTER;
};
export const updateExercisesInChapter = ({
  chapterRef,
  exercises,
  progress = {},
}: any): UpdateExercisesInChapterAction => ({
  type: UPDATE_EXERCISES_IN_CHAPTER,
  chapterRef,
  exercises,
  progress,
});

export const MID_RESET_COURSE_PROGRESS = 'course/MID_RESET_COURSE_PROGRESS';
export type MidResetCourseProgressAction = {
  courseId: string;
  redirection: any;
  type: typeof MID_RESET_COURSE_PROGRESS;
};
export const midResetCourseProgress = ({
  courseId,
  redirection,
}: any): MidResetCourseProgressAction => ({
  type: MID_RESET_COURSE_PROGRESS,
  redirection,
  courseId,
});

export type CourseAction =
  | EpicGetExercisesFromChapterAction
  | MidResetCourseProgressAction
  | UpdateCourseProgressAction
  | UpdateExercisesInChapterAction;
