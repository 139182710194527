import type { Action } from '../actions';
import {
  SET_DATAWAREHOUSE_SESSION,
  SET_DATAWAREHOUSE_SESSION_ERROR,
  SET_DATAWAREHOUSE_SESSION_LOADING,
} from '../actions';

export type DatawarehouseSessionInfo = {
  dbName: string;
  dbPassword: string;
  dbRole: string;
  dbUser: string;
  sessionId: string;
};

type InitialDatawarehouseSession = {
  status: 'initial';
};
type LoadingDatawarehouseSession = {
  courseId: number;
  status: 'loading';
};
type LoadedDatawarehouseSession = {
  courseId: number;
  session: DatawarehouseSessionInfo;
  status: 'success';
};
type ErrorDatawarehouseSession = {
  courseId: number;
  error: unknown;
  status: 'error';
};
type LoadedNoDatawarehouseSession = {
  courseId: number;
  status: 'no-session';
};

export type DatawarehouseSessionState =
  | ErrorDatawarehouseSession
  | InitialDatawarehouseSession
  | LoadedDatawarehouseSession
  | LoadedNoDatawarehouseSession
  | LoadingDatawarehouseSession;

const initialState: DatawarehouseSessionState = { status: 'initial' };

export default (
  state: DatawarehouseSessionState = initialState,
  action: Action,
): DatawarehouseSessionState => {
  const isLoadingAnotherCourse =
    state.status === 'loading' &&
    'courseId' in action &&
    action.courseId !== state.courseId;
  switch (action.type) {
    case SET_DATAWAREHOUSE_SESSION:
      if (isLoadingAnotherCourse) {
        return state;
      }
      if (action.session) {
        return {
          courseId: action.courseId,
          status: 'success',
          session: action.session,
        };
      }
      return {
        courseId: action.courseId,
        status: 'no-session',
      };
    case SET_DATAWAREHOUSE_SESSION_LOADING:
      return {
        courseId: action.courseId,
        status: 'loading',
      };
    case SET_DATAWAREHOUSE_SESSION_ERROR:
      if (isLoadingAnotherCourse) {
        return state;
      }
      return {
        courseId: action.courseId,
        error: action.error,
        status: 'error',
      };
    default:
      return state;
  }
};
