// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/datacamp__dc-languages-con... Remove this comment to see the full error message
import { mapLanguageName } from '@datacamp/dc-languages-config';
import get from 'lodash/get';

import ApiClient from '../../../helpers/ApiClient';
import {
  getCampusApiClient,
  getTeachClient,
} from '../../../helpers/getClients';
import { DEFAULT_LANGUAGE } from '../../../i18n';
import type { PreFetchedDataState } from '../../reducers/preFetchedData';
import { BOOT_ERRORS } from '../../selectors/boot';
import { REQUEST_STATUS } from '../../selectors/preFetchedData';

import createError from './createError';

function bootErrorFromMainAppError(error: any) {
  const status = get(error, 'error.status', null);
  const message = get(error, 'error.response.text', null);
  const isCourseLocked =
    status === 400 && message != null && message.includes('Course is locked');
  const isCourseNotFound = status === 404;
  return createError({
    message:
      isCourseLocked || isCourseNotFound
        ? BOOT_ERRORS.COURSE_NOT_FOUND
        : BOOT_ERRORS.UNKNOWN,
    originalError: error,
  });
}

export default ({
  authorizationHeader,
  courseRef,
  isPreview,
  language = DEFAULT_LANGUAGE,
  preFetchedData,
}: {
  authorizationHeader: string | undefined;
  courseRef: number | string;
  isPreview: boolean;
  language?: string;
  preFetchedData: PreFetchedDataState;
}) => {
  const preFetchedDataRequest = preFetchedData.get('course');
  switch (preFetchedDataRequest.get('status')) {
    case REQUEST_STATUS.SUCCESS:
      return Promise.resolve(preFetchedDataRequest.get('data'));
    case REQUEST_STATUS.FAILED:
      return Promise.reject(
        createError({ message: preFetchedDataRequest.get('data') }),
      );
    default: {
      const client = isPreview ? getTeachClient() : getCampusApiClient();

      return (
        client
          .execute(
            ApiClient.endpoint.getCourse(encodeURIComponent(courseRef), {
              language,
              authorizationHeader,
            }),
          )
          .toPromise()
          // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'course' implicitly has an 'any' t... Remove this comment to see the full error message
          .then(({ body: course }) => ({
            ...course,
            programming_language: mapLanguageName(course.programming_language),
            // TODO: because of data inconsistency, id has to be overwritten for teach preview
            //       https://github.com/datacamp/teach-bugs/issues/425
            ...(isPreview ? { id: courseRef } : {}),
          }))
          .catch((error: any) =>
            Promise.reject(bootErrorFromMainAppError(error)),
          )
      );
    }
  }
};
