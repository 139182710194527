import type { ModalCode } from '../selectors';

export const REMOVE_MODAL = 'settings/REMOVE_MODAL';
export type IRemoveModalAction = {
  force: boolean;
  type: typeof REMOVE_MODAL;
};
type RemoveModalArgs = {
  force?: boolean;
};
export const removeModal = ({
  force = false,
}: RemoveModalArgs = {}): IRemoveModalAction => ({ type: REMOVE_MODAL, force });

export const SHOW_PAYWALL_MODAL = 'settings/SHOW_PAYWALL_MODAL';
export const showPaywallModal = () => ({ type: SHOW_PAYWALL_MODAL });

export const SHOW_MODAL = 'settings/SHOW_MODAL';
export type ShowModalAction = {
  modal: {
    [key: string]: any;
    code: ModalCode;
  };
  type: typeof SHOW_MODAL;
};
export const showModal = ({
  modal,
}: Omit<ShowModalAction, 'type'>): ShowModalAction => ({
  type: SHOW_MODAL,
  modal,
});

export const UPDATE_MODAL_SETTINGS = 'settings/UPDATE_MODAL_SETTINGS';
export const updateModalSettings = (settings: any) => ({
  type: UPDATE_MODAL_SETTINGS,
  settings,
});

export const EPIC_SUBMIT_ISSUE = 'settings/EPIC_SUBMIT_ISSUE';
export type EpicSubmitIssueAction = {
  category: unknown;
  type: typeof EPIC_SUBMIT_ISSUE;
  userFeedback?: unknown;
};
export const epicSubmitIssue = ({
  category,
  userFeedback,
}: Omit<EpicSubmitIssueAction, 'type'>): EpicSubmitIssueAction => ({
  type: EPIC_SUBMIT_ISSUE,
  userFeedback,
  category,
});

export const EPIC_SUBMIT_FEEDBACK = 'settings/EPIC_SUBMIT_FEEDBACK';
export type EpicSubmitFeedbackAction = {
  rating: number;
  type: typeof EPIC_SUBMIT_FEEDBACK;
  userFeedback?: unknown;
};
export const epicSubmitFeedback = ({
  rating,
  userFeedback,
}: Omit<EpicSubmitFeedbackAction, 'type'>): EpicSubmitFeedbackAction => ({
  type: EPIC_SUBMIT_FEEDBACK,
  userFeedback,
  rating,
});

export const RESET_ISSUE_MODAL = 'settings/RESET_ISSUE_MODAL';
export const resetIssueModal = () => ({ type: RESET_ISSUE_MODAL });

export const FIRST_TIME_COMPLETED_CHAPTERS =
  'settings/FIRST_TIME_COMPLETED_CHAPTERS';
export const firstTimeCompletedChapters = () => ({
  type: FIRST_TIME_COMPLETED_CHAPTERS,
});

export const INCREMENT_SHORTCUT_USAGE = 'settings/INCREMENT_SHORTCUT_USAGE';
export const incrementShortcutUsage = () => ({
  type: INCREMENT_SHORTCUT_USAGE,
});

export const CLOSE_SHORTCUT_FRAME = 'settings/CLOSE_SHORTCUT_FRAME';
export const closeShortcutFrame = () => ({ type: CLOSE_SHORTCUT_FRAME });

export const RESIZE_EDITORS = 'settings/RESIZE_EDITORS';
export type ResizeEditorsAction = {
  components: any;
  console: any;
  dimensions: any;
  type: typeof RESIZE_EDITORS;
};
export const resizeEditors = ({
  components,
  console,
  dimensions,
}: any): ResizeEditorsAction => ({
  type: RESIZE_EDITORS,
  components,
  dimensions,
  console,
});
export const RUN_CODE_USAGE = 'settings/RUN_CODE_USAGE';
export const runCodeUsage = () => ({ type: RUN_CODE_USAGE });

export const TOGGLE_UI_THEME = 'settings/TOGGLE_UI_THEME';
export const toggleUITheme = () => ({ type: TOGGLE_UI_THEME });

export const SET_MOBILE_VIEW = 'settings/SET_MOBILE_VIEW';
export const setMobileView = (selectedView: 'CONTEXT' | 'EXERCISE') => ({
  type: SET_MOBILE_VIEW,
  selectedView,
});

export type SettingActions =
  | EpicSubmitFeedbackAction
  | EpicSubmitIssueAction
  | EpicSubmitIssueAction
  | IRemoveModalAction
  | ShowModalAction;
