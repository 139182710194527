import { t } from 'i18next';
import { List as list } from 'immutable';
import get from 'lodash/get';
import round from 'lodash/round';
import startCase from 'lodash/startCase';
import { createSelector } from 'reselect';

import config from '../../config';
import type { TechnologyId } from '../../helpers/technology';
import { technologyById } from '../../helpers/technology';
import type { TopicId } from '../../helpers/topic';
import { topicById } from '../../helpers/topic';
import { getImageAndRuntimeConfig } from '../middlewares/getImageAndRuntimeConfig';

import {
  selectChapter,
  selectChapterId,
  selectCompletedChapterAlreadyShown,
} from './chapter';
import {
  selectExercise,
  selectExercises,
  selectExercisesProgress,
  selectGlobalUserProgress,
} from './exercises';
import { selectImages } from './images';
import { selectLearningMode } from './learningMode';

export const selectCourse = (state: any) => state.get('course');
export const selectCourseId = createSelector(selectCourse, (course) => {
  if (course === undefined) {
    return undefined;
  }
  return course.get('id');
});
export const selectLanguage = createSelector(
  selectCourse,
  selectExercise,
  (course, exercise) => {
    if (course === undefined) {
      return 'r';
    }
    const language = course.get('programming_language', 'r');
    const exerciseLanguage = exercise.get('programming_language');
    return exerciseLanguage || language;
  },
);
export const selectRuntimeConfig = createSelector(
  selectCourse,
  selectExercise,
  selectImages,
  (course, exercise, images): string | undefined => {
    if (course == null) {
      return '';
    }
    const { runtimeConfig } = getImageAndRuntimeConfig({
      course: course.toJS(),
      exercise: exercise.toJS(),
      images,
    });
    return runtimeConfig;
  },
);
export const selectSharedImage = createSelector(selectCourse, (course) =>
  course.get('shared_image'),
);

export const selectTechnologyId = createSelector(
  selectCourse,
  (course): TechnologyId => course.get('technology_id'),
);

export const selectTechnology = createSelector(
  selectTechnologyId,
  (technologyId) => technologyById[technologyId],
);

export const selectTopicId = createSelector(
  selectCourse,
  (course): TopicId => course.get('topic_id'),
);

export const selectTopic = createSelector(
  selectTopicId,
  (topicId) => topicById[topicId],
);

export const selectCourseProgress = (state: any) =>
  state.getIn(['course', 'progress']);
export const selectChapterIndex = createSelector(
  selectChapter,
  (chapter) => chapter.get('number', undefined) - 1,
);
export const selectCurrentChapterProgress = (state: any) =>
  state.getIn([
    'course',
    'progress',
    'user_chapters',
    selectChapterIndex(state),
  ]) || null;
export const selectCourseCaseStudy = (state: any) =>
  selectCourse(state).get('case_study');
export const selectCourseImageURL = (state: any) =>
  selectCourse(state).get('image_url');
export const selectCourseDatasets = (state: any) =>
  selectCourse(state).get('datasets', list()) as list<any>;
export const selectCourseTitle = (state: any) =>
  selectCourse(state).get('title');
export const selectCourseRef = (state: any) =>
  selectCourse(state).get('slug') || selectCourse(state).get('id');
export const selectChapters = (state: any) =>
  state.getIn(['course', 'chapters'], list());
export const selectNextChapter = createSelector(
  selectChapters,
  selectChapterIndex,
  (chapters, chapterIndex) => chapters.get(chapterIndex + 1, null),
);
export const selectPreviousChapter = createSelector(
  selectChapters,
  selectChapterIndex,
  (chapters, chapterIndex) =>
    chapterIndex > 0 ? chapters.get(chapterIndex - 1, null) : null,
);

export const selectShouldSeeChapterRating = createSelector(
  selectChapterId,
  selectCurrentChapterProgress,
  selectCourseProgress,
  selectCompletedChapterAlreadyShown,
  (chapterId, chapterProgress, courseProgress, firstTimeCompletedChapters) =>
    !firstTimeCompletedChapters.get(chapterId) &&
    !(chapterProgress && chapterProgress.get('completed')) &&
    !(courseProgress && courseProgress.get('completed')),
);

export const selectShouldSeeExerciseRating = createSelector(
  selectCourse,
  selectExercise, // Prevents caching of the random number so it is recalculated each time.
  selectLearningMode,
  (course, exercise, learningMode) => {
    const aasmState = course.get('state');
    const random = exercise.get('randomNumber');
    if (learningMode === 'singleExercise') {
      return false;
    }
    return (
      aasmState === 'beta_testing' ||
      (aasmState === 'soft_launched' && random < 0.3) ||
      (aasmState === 'live' && random < 0.1)
    );
  },
);

/**
 * Return the current progression of the user for all chapters of this course.
 *
 * Note: For the current chapter, where the user is,
 * it must be handled differently because the data may be inconsistent.
 *
 * The place where actual completion updates are found while the user is
 * practicing is currently merged into the exercises.all state under a "user"
 * property, that's what currentChapterExercisesState is holding.
 *
 * Currently, the next exercise expected is defined as such:
 * - the next exercise should be the exercise coming after the latest completed exercise.
 *   note: taking into account that the user may skip exercises.
 * - In the case where the next exercise is not applicable (everything completed), fallback to first one.
 *
 * Because it is currently impossible to have a consistent and correct "next exercise",
 * the link to the next exercise will use the main app "continue".
 * eg: www.datacamp.com/courses/2922/chapters/8014/continue
 *
 * TODO: The real solution should be to fix the data inconsistency.
 */
export const selectUserProgress = createSelector(
  selectCourse,
  selectExercisesProgress,
  selectChapter,
  selectExercises,
  (
    courseState,
    exercisesProgressState,
    currentChapterState,
    currentChapterExercisesState,
  ) => {
    const course = courseState.toJS();
    const chapterKeyWord = startCase(t('glossary:chapter', { count: 1 }));

    let progress = [];

    if (Array.isArray(course.chapters)) {
      progress = course.chapters.map((chapter: any, index: any) => ({
        id: `chapter-${chapter.id}`,
        label: `${chapterKeyWord} ${index + 1}`,
        progress: 0,
      }));

      // We get the progress on the client side.
      if (
        course.progress &&
        exercisesProgressState &&
        currentChapterExercisesState
      ) {
        const currentChapterId = currentChapterState.get('id');

        // For current chapter only
        const completedExercicePredicate = (
          exerciseProgress: any,
          index: any,
        ) =>
          exerciseProgress.get('completed_at') !== null ||
          currentChapterExercisesState.getIn(
            [index, 'user', 'completed', 'completed'],
            false,
          );

        const currentChapterCompletion = {
          nb_completed_exercises: exercisesProgressState.count(
            completedExercicePredicate,
          ),
        };

        progress = course.progress.user_chapters.map(
          (userChapter: any, index: any) => {
            const chapter = course.chapters[index];
            const userChapterProgress =
              userChapter.chapter_id === currentChapterId
                ? currentChapterCompletion
                : userChapter;
            return {
              id: `chapter-${userChapter.chapter_id}`,
              label: `${chapterKeyWord} ${index + 1}`,
              link: `${config.urls.datacamp}/courses/${course.id}/chapters/${chapter.id}/continue`,
              progress: round(
                100 *
                  (userChapterProgress.nb_completed_exercises /
                    chapter.nb_exercises),
              ),
            };
          },
        );
      }
    }

    return progress;
  },
);

export const selectIsReducedOutlineCourse = createSelector(
  selectCourse,
  (course) => course.get('reduced_outline'),
);

export const selectProgressIndicatorVisible = createSelector(
  selectIsReducedOutlineCourse,
  selectLearningMode,
  (isReducedOutlineCourse, learningMode) => {
    if (learningMode === 'singleChapter') {
      return false;
    }

    return learningMode !== 'singleExercise' && !isReducedOutlineCourse;
  },
);

export const selectIsChapterRatingOpen = createSelector(
  selectShouldSeeChapterRating,
  selectGlobalUserProgress,
  (shouldSeeChapterRating, userProgress) =>
    (get(userProgress, 'course.completed', false) && shouldSeeChapterRating) ||
    (get(userProgress, 'chapter.completed', false) && shouldSeeChapterRating),
);
