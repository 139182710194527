import cloneDeep from 'lodash/cloneDeep';

import type { State } from '../../interfaces/State';
import type {
  StreakInfoState,
  StreakWeekItem,
} from '../reducers/streaks/types';
import { StreakInfoType } from '../reducers/streaks/types';
import { DAILY_XP_GOAL } from '../reducers/streaks/v1/streakInfo';

export const selectStreakInfoReady = (state: State): boolean => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  return streakInfo.type === StreakInfoType.Known;
};

export const selectStreakDailyXp = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  return streakInfo.dailyXp;
};

export const selectStreakDays = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  if (streakInfo.version === 1) {
    return streakInfo.lengthInDays;
  }

  return streakInfo.current.streak.days;
};

export const selectStreakDailyGoalMetInSession = (state: State): boolean => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return false;
  }

  return streakInfo.streakGoalMetInSession;
};

export const selectStreakFreezesAwardedInSession = (state: State): number => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown || streakInfo.version !== 2) {
    return 0;
  }

  return streakInfo.freezesAwardedInSession;
};

export const selectDailyStreakScreenVisible = (state: State): boolean => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return false;
  }

  return streakInfo.screenVisible;
};

export const selectStreakDailyGoalMet = (state: State): boolean => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return false;
  }

  if (streakInfo.version === 1) {
    return streakInfo.dailyXp >= DAILY_XP_GOAL;
  }

  return streakInfo.current.streak_goal_met;
};

export const selectStreakDailyGoal = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  if (streakInfo.version === 1) {
    return DAILY_XP_GOAL;
  }

  return streakInfo.streakGoal;
};

export const selectStreakIncrementedAt = (state: State): string | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  if (streakInfo.version === 1) {
    return streakInfo.incrementedAt;
  }

  return streakInfo.current.streak.last_incremented_at;
};

export const selectStreakDeadline = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  if (streakInfo.version === 1) {
    return streakInfo.deadline;
  }

  return Date.parse(streakInfo.current.streak_deadline);
};

const streakWeekViewMock: StreakWeekItem[] = [
  {
    date: '2024-09-16',
    weekday: 'Mon',
    status: 'unmet',
  },
  {
    date: '2024-09-17',
    weekday: 'Tue',
    status: 'unmet',
  },
  {
    date: '2024-09-18',
    weekday: 'Wed',
    status: 'unmet',
  },
  {
    date: '2024-09-19',
    weekday: 'Thu',
    status: 'unmet',
  },
  {
    date: '2024-09-20',
    weekday: 'Fri',
    status: 'unmet',
  },
  {
    date: '2024-09-21',
    weekday: 'Sat',
    status: 'unmet',
  },
  {
    date: '2024-09-22',
    weekday: 'Sun',
    status: 'unmet',
  },
];
export const selectStreakWeekView = (state: State): StreakWeekItem[] => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return [];
  }

  if (streakInfo.version === 1) {
    if (!selectStreakDailyGoalMet(state)) {
      return streakWeekViewMock;
    }
    const todayIndex = (new Date().getDay() - 1 + 7) % 7;
    const newWeekView = cloneDeep(streakWeekViewMock);
    newWeekView[todayIndex].status = 'met';
    return newWeekView;
  }

  return streakInfo.current.streak_week_view;
};
