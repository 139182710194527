import type { SessionOutput } from '@datacamp/multiplexer-client';

import type { AiFlags } from '../../helpers/ai/getUserEnabledForAiFlags';

export const UPDATE_USER = 'user/UPDATE_USER';

export const SUBMIT_TO_MAIN_APP = 'user/SUBMIT_TO_MAIN_APP';
export type SubmitToMainAppAction = {
  code: string;
  correct: boolean;
  lti: unknown;
  message: string;
  type: typeof SUBMIT_TO_MAIN_APP;
  xp?: number;
};
export const submitToMainApp = ({
  code,
  correct,
  lti,
  message,
  xp,
}: any): SubmitToMainAppAction => ({
  type: SUBMIT_TO_MAIN_APP,
  correct,
  message,
  code,
  lti,
  xp,
});

export const REPORT_OUTPUT_TO_HEAP = 'user/REPORT_OUTPUT_TO_HEAP';
export type ReportOutputToHeap = {
  output: SessionOutput;
  type: typeof REPORT_OUTPUT_TO_HEAP;
};
export const reportOutputToHeap = (
  output: SessionOutput,
): ReportOutputToHeap => ({
  type: REPORT_OUTPUT_TO_HEAP,
  output,
});

export const USER_ENABLED_FOR_AI_FEATURES_FETCHED =
  'user/settings/USER_ENABLED_FOR_AI_FEATURES_FETCHED';
export type UserEnabledForAiFeaturesFetched = {
  aiFlags: AiFlags;
  type: typeof USER_ENABLED_FOR_AI_FEATURES_FETCHED;
};
export const userEnabledForAiFeaturesFetched = (
  aiFlags: AiFlags,
): UserEnabledForAiFeaturesFetched => ({
  type: USER_ENABLED_FOR_AI_FEATURES_FETCHED,
  aiFlags,
});

export type UserAction = SubmitToMainAppAction;
