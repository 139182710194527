import { getTreatment } from '../../../helpers/featureFlags';
import type { Reducer } from '../../../interfaces/State';

import type { StreakInfoState } from './types';
import { StreakInfoType } from './types';
import streakInfoReducerV1 from './v1/streakInfo';
import streakInfoReducerV2 from './v2/streakInfo';

function shouldUseV2(state: StreakInfoState | undefined) {
  if (state && state.type !== StreakInfoType.Unknown) {
    return state.version === 2;
  }

  const treatment = getTreatment('wl--streak-freezes');

  return treatment === 'on';
}

const streakInfoReducer: Reducer<StreakInfoState> = (state, action) => {
  const isOn = shouldUseV2(state);

  return isOn
    ? streakInfoReducerV2(state, action)
    : streakInfoReducerV1(state, action);
};

export default streakInfoReducer;
