import { createSelector } from 'reselect';

import type { State } from '../../interfaces/State';
import type { NotesState } from '../reducers/notes';

export const selectNotes = (state: State): NotesState => state.get('notes');

export const selectWorkspaceTemplate = createSelector(
  selectNotes,
  (notesState) => notesState.workspaceTemplate,
);

export const showNoteTaking = createSelector(
  selectWorkspaceTemplate,
  (workspaceTemplate) => workspaceTemplate != null,
);
