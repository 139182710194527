import TimetrackerClient from '@datacamp/timetracker-client';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import config from '../../config';
import { isTeachPreview } from '../../helpers/isTeachPreview';
import { isUserLoggedIn } from '../../helpers/user';
import * as actions from '../actions';
import * as selectors from '../selectors';

const timetrackerClient = new TimetrackerClient(
  config.urls.timetracker,
  'campus',
);

export const epicLoggedIn = (action$: any) =>
  action$
    .ofType(actions.BOOT_SUCCEEDED)
    .filter(
      (action: any) =>
        action.entities.user.status === selectors.USER_STATUS.LOGIN,
    )
    .do((action: any) => {
      const userId = action.entities.user.settings.id;
      timetrackerClient.setUserId(userId);
    })
    .concatMapTo([]);

export const epicStartTimeTracking = (action$: any, store: any, history: any) =>
  action$
    .ofType(actions.UPDATE_CURRENT_EXERCISE, actions.BOOT_SUCCEEDED) // == newExercise$
    .filter(() => isUserLoggedIn(selectors.selectUser(store.getState()).toJS()))
    .filter(() => !isTeachPreview(history.location.pathname))
    .do(() => {
      const state = store.getState();
      const courseId = selectors.selectCourse(state).get('id');
      const chapterId = selectors.selectChapter(state).get('id');
      const exerciseId = selectors.selectExercise(state).get('id');
      timetrackerClient.startTracking(courseId, chapterId, exerciseId);
    })
    // Perform a pure side effect: we don't output any new action from this epic:
    .concatMapTo(Rx.Observable.empty());

const videoTimeupdateSubject = timetrackerClient.manuallyActiveSubject;

// Manually log timetracker activity when the user is watching a video
// Ideally we would have a general solution for tackling this in the
// exercise contract
window.addEventListener('message', (event) => {
  if (event.origin == null) {
    return;
  }
  const allowedOrigin =
    event.origin.endsWith('datacamp.com') || event.origin.endsWith('localhost');
  if (!allowedOrigin) {
    return;
  }

  const shouldManuallyRegisterVideoExerciseActivity =
    event.data != null && event.data.type === 'UPDATE_TIME';

  if (shouldManuallyRegisterVideoExerciseActivity) {
    videoTimeupdateSubject.next();
  }
});
