import type { Images } from '../../helpers/ApiClient';
import type { LearningRecapState } from '../reducers/learningRecap';
import type { WorkspaceTemplate } from '../reducers/notes';
import type {
  IPreFetchedRequest,
  PrefetchedData,
  PreFetchedDataState,
} from '../reducers/preFetchedData';

export const BOOT = 'boot/BOOT';
export const BOOT_SUCCEEDED = 'boot/BOOT_SUCCEEDED';
export const BOOT_FAILED = 'boot/BOOT_FAILED';

export type Location = {
  params: Record<string, string>;
  pathname: string;
  url: string;
};

export type IBootAction = {
  authorizationHeader: string | undefined;
  chapterRef: number | string | null;
  courseRef: number | string;
  isPreview: boolean;
  language: string;
  originalLocation: Location;
  preFetchedData: PreFetchedDataState;
  queryParams: Record<string, string>;
  type: typeof BOOT;
};
export const boot = ({
  authorizationHeader,
  chapterRef = null,
  courseRef,
  isPreview,
  language,
  originalLocation,
  preFetchedData,
  queryParams,
}: Omit<IBootAction, 'type'>): IBootAction => ({
  type: BOOT,
  courseRef,
  chapterRef,
  authorizationHeader,
  isPreview,
  queryParams,
  language,
  originalLocation,
  preFetchedData,
});

export type StreakInfoResponse = {
  daily_xp: number;
  freezes_available: number;
  streak: {
    created_at?: string;
    days: number;
    last_incremented_at?: string;
  };
  streak_deadline: string;
  streak_goal: number;
  streak_goal_met: boolean;
  streak_state_after_goal_met: {
    freezes_available: number;
    streak: {
      days: number;
    };
    streak_deadline: string;
    streak_goal_met: boolean;
    streak_state_after_goal_met: null;
    streak_week_view: Array<{
      date: string;
      status: 'frozen' | 'met' | 'unmet';
      weekday: 'Fri' | 'Mon' | 'Sat' | 'Sun' | 'Thu' | 'Tue' | 'Wed';
    }>;
  } | null;
  streak_week_view: Array<{
    date: string;
    status: 'frozen' | 'met' | 'unmet';
    weekday: 'Fri' | 'Mon' | 'Sat' | 'Sun' | 'Thu' | 'Tue' | 'Wed';
  }>;
};

type Entities = {
  chapterProgress?: Record<string, unknown>;
  course: Record<string, unknown>;
  courseProgress?: Record<string, unknown>;
  exercises: Array<Record<string, unknown>>;
  images: Images;
  streakInfo?: StreakInfoResponse;
  user?: Record<string, unknown>;
  workspaceTemplate: WorkspaceTemplate | null;
};

export type IBootSucceedAction = {
  canonical: boolean;
  chapterId: number;
  courseId: number;
  entities: Entities;
  exerciseNumber: number;
  language: string;
  learningRecap: LearningRecapState;
  pathname: string;
  preFetchedData: Record<string, IPreFetchedRequest>;
  queryParams: Record<string, string>;
  type: typeof BOOT_SUCCEEDED;
};
export const bootSucceeded = ({
  canonical,
  chapterId,
  courseId,
  entities,
  exerciseNumber,
  language,
  learningRecap,
  pathname,
  preFetchedData,
  queryParams,
}: Omit<IBootSucceedAction, 'type'>): IBootSucceedAction => ({
  type: BOOT_SUCCEEDED,
  courseId,
  chapterId,
  exerciseNumber,
  entities,
  pathname,
  queryParams,
  language,
  learningRecap,
  canonical,
  preFetchedData,
});

export const succeedBoot = (args: Omit<IBootSucceedAction, 'type'>) => async (
  dispatch: any,
) => {
  await dispatch(bootSucceeded(args));
};

export type IBootFailedAction = {
  error: string;
  originalError: Error | null;
  preFetchedData: PrefetchedData;
  type: typeof BOOT_FAILED;
};
export const bootFailed = ({
  error,
  originalError,
  preFetchedData,
}: Omit<IBootFailedAction, 'type'>): IBootFailedAction => ({
  type: BOOT_FAILED,
  error,
  originalError,
  preFetchedData,
});

export type BootActions = IBootAction | IBootFailedAction | IBootSucceedAction;
